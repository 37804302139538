/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/Diploma.png"
import cardImg from "assets/images/Parkeren.png"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function A2025_01_25_Examens() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={logo}
          category={{ color: "warning", label: "hub" }}
          title="Band Examens Karate"
          description="Op Zaterdag 25 januari zijn er weer de band examens voor Karate."
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 03-01-2025"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                Band Examens Karate
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="350em" alt="Flyer" title="Flyer" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Examens
              </MKTypography>
              <p>
                De examens vinden normaal 2x per jaar plaats. De eerste voor dit jaar zal op 25-01-2025 plaatvinden.
                Dit is in onze eigen Dojo (<a>maps</a>). <br />
                Kijk voor de zekerheid op de foto voor de beste plaatsen om te parkeren. (Blauwe strepen).<br />
                let op de borden ivm boetes.<br />
                De grotere parkeerplaats bij de sporthal is ongeveer 150m lopen.<br />
              </p>
              <p style={{marginTop:"20px"}}>
                Bij dit examen zijn er 7 kandidaten van de jeugd die hun best gaan doen voor de 8e Kyu (Half Gele band).<br />
                Het examen zal om 18:00 starten en naar verwachting deze keer ongeveer een uurtje duren.<br />
                Natuurlijk mogen de ouders, opas, omas en ander familie ook komen kijken.


              </p>
            </Grid>




          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default A2025_01_25_Examens;
