/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/News/2025-01-25_Examens/14.jpg"
import foto1 from "assets/images/News/2025-01-25_Examens/1.jpg"
import foto2 from "assets/images/News/2025-01-25_Examens/2.jpg"
import foto3 from "assets/images/News/2025-01-25_Examens/3.jpg"
import foto4 from "assets/images/News/2025-01-25_Examens/4.jpg"
import foto5 from "assets/images/News/2025-01-25_Examens/5.jpg"
import foto6 from "assets/images/News/2025-01-25_Examens/6.jpg"
import foto7 from "assets/images/News/2025-01-25_Examens/7.jpg"
import foto8 from "assets/images/News/2025-01-25_Examens/8.jpg"
import foto9 from "assets/images/News/2025-01-25_Examens/9.jpg"
import foto10 from "assets/images/News/2025-01-25_Examens/10.jpg"
import foto11 from "assets/images/News/2025-01-25_Examens/11.jpg"
import foto12 from "assets/images/News/2025-01-25_Examens/12.jpg"
import foto13 from "assets/images/News/2025-01-25_Examens/13.jpg"
import foto14 from "assets/images/News/2025-01-25_Examens/14.jpg"
import foto15 from "assets/images/News/2025-01-25_Examens/15.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function N2025_01_25_Examens() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="Band Examens"
          description="Zaterdag 25-01-2025 onze eerste Wado Karate band examens in het nieuwe BudoCentrum in Sliedrecht"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 25-01-2025"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                Band Examens Januari 2025
              
              
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="450em" alt="Opening" title="Foto van de opening" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Band Examens Januari 2025
              </MKTypography>
              <p>
                Op Zaterdag 25 Janurai ware er 7 (nerveuze) kandidaten die voor de eerste keer examen mochten doen voor een nieuwe band.<br />
                Ondanks de zenuwen en het publiek van familie, hebben ze allemaal hun uiterste best gedaan.< br />
                Dit natuurlijk met veel plezier en goed resultaat. Iedereen is GESLAAGD!!<br />
                Daarom nogmaals hartelijk gefeliciteerd.                
              </p>
            </Grid>

            <Grid 
              container
              style={{
                backgroundColor: "#000",
                padding: "5em"
              }}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto1} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto2} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto3} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto4} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto5} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto6} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto7} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto8} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto9} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto10} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto11} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto12} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto13} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto14} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto15} size="450em" alt="Foto Examens" title="Foto Examens" />
              </Grid>
            </Grid>

          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default N2025_01_25_Examens;
