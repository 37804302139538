/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/Acitivities/2025-03-01-Daniel Dubreuil.jpeg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function A2025_03_01_Daniel_Dubreuil() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="Dani&euml;l Dubreuil"
          description=" Op 01-03 en 02-03 2025: De jaarlijkse stage van Dani&euml;l Dubreuil voor Aikibudo en Katori Shinto Ryu"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 03-01-2025"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                De jaarlijkse stage van Dani&euml;l Dubreuil.
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="350em" alt="Flyer" title="Flyer" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Stage
              </MKTypography>
              <p>
                Deze stage van Daniël Dubreuil zal ook deze keer weer bestaan uit 2 dagen met 2 trainingen per dag.<br />
                De ene dag is Aikibudo de andere is Katori Shinto Ryu.<br />

                <b>Dag 1: (01-03-2025)</b>
                <ul>
                  <li><u><i>10:00-12:30:</i></u> Aikibudo</li>
                  <li><u><i>14:00-16:30:</i></u> Aikibudo</li>
                </ul>

                <b>Dag 2: (02-03-2025)</b>
                <ul>
                  <li><u><i>10:00-12:30:</i></u> Katori Shinto Ryu</li>
                  <li><u><i>14:00-16:30:</i></u> Katori Shinto Ryu</li>
                </ul>
                <b>Kosten: (Niet JBN leden +€5,-)</b>
                <ul>
                  <li><u><i>Dagdeel:</i></u> €20,-</li>
                  <li><u><i>Dag:</i></u> €35,-</li>
                  <li><u><i>Weekend:</i></u> €60,-</li>
                </ul>
              </p>
            </Grid>




          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default A2025_03_01_Daniel_Dubreuil;
