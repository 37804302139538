/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ImageOverlay from 'components/ImageOverlay';

// Images
import logo from "assets/images/logo.png"
import cardImg from "assets/images/News/2025-03-23_Proefmaand/Foto.JPG"
import foto1 from "assets/images/News/2025-03-23_Proefmaand/1.jpg"
import foto2 from "assets/images/News/2025-03-23_Proefmaand/2.jpg"
import foto3 from "assets/images/News/2025-03-23_Proefmaand/3.jpg"
import foto4 from "assets/images/News/2025-03-23_Proefmaand/4.jpg"
import foto5 from "assets/images/News/2025-03-23_Proefmaand/5.jpg"
import foto6 from "assets/images/News/2025-03-23_Proefmaand/6.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function N2025_03_23_Proefmaand() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} lg={3}>
        <CenteredBlogCard
          image={cardImg}
          category={{ color: "warning", label: "hub" }}
          title="Gratis Proefmaand Wado"
          description="In de maand mei is het mogelijk om bij Wado Karate om een hele maand gratis mee te trainen!"
          author={{
            display: true,
            name: "Sportschool Tromp",
            date: "Geplaats op 23-03-2025"
          }}
          action={{
            type: "callback",
            callback: handleClickOpen,
          }}
        />
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                
                { /***** TITLE *****/}
                Gratis Proefmaand Wado Karate
              
              
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          { /***** MESSAGE *****/}

            <Grid item xs={12} lg={3}>
              <ImageOverlay image={cardImg} size="450em" alt="Opening" title="Foto tijdens de examens" />
            </Grid>
            
            
            <Grid item xs={12} lg={9}>
              <MKTypography
                variant="h3"
                color="black"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Gratis Proefmaand Wado Ryu Karate: doe de allerbeste investering in jezelf.
              </MKTypography>
              <p>                
                In de maand mei willen wij geïnteresseerden gratis kennis laten maken met onze Wado Ryu Karate lessen. Dat kan met mee te trainen op onze locaties in Nieuw-Lekkerland, Streefkerk en Sliedrecht. Gewoon komen kijken mag ook. 
              </p>
              
              <MKTypography
                variant="h3"
                color="black"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Wado Karate is geschikt voor iedere leeftijd. Onze jongste deelnemer is 6 jaar en de oudste is 71 jaar.
              </MKTypography>
              <p>
                Bij Wado Karate zijn niet alleen aangeleerde technieken belangrijk, maar ook persoonlijke groei en ontwikkeling. Wado Karate is zeer geschikt voor volwassenen die actief willen blijven tot op hoge leeftijd. Beweging, balans en flexibiliteit worden op latere leeftijd zeer belangrijk. Tijdens de trainingen wordt altijd rekening gehouden met het niveau en mogelijkheden in een prettige ongedwongen sfeer. 
              </p>
              
              <MKTypography
                variant="h3"
                color="black"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Contactinformatie
              </MKTypography>
              <p>
                Heb je vragen of wil je je direct aanmelden? 
                Stuur een email maar <a href="mailto:molenlanden@sportschooltromp.nl">molenlanden@sportschooltromp.nl</a>, of kijk verder op onze onze website. We houden je graag op de hoogte van alle nieuwtjes en evenementen!
                Neem de eerste stap is nooit te laat.
              </p>
            </Grid>
            
            <Grid 
              container
              style={{
                backgroundColor: "#000",
                padding: "5em"
              }}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto1} size="450em" alt="Foto Max" title="Foto Sliedrecht" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto2} size="450em" alt="Foto" title="Foto Nieuw-Lekkerland" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto3} size="450em" alt="Foto" title="Foto Demo Open dag" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto4} size="450em" alt="Foto" title="Foto Nieuw-Lekkerland stage" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto5} size="450em" alt="Foto" title="Foto Sliedrecht" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ImageOverlay image={foto6} size="450em" alt="Foto" title="Foto Nieuwe zaal in Sliedrecht" />
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    </>
  );
}

export default N2025_03_23_Proefmaand;
