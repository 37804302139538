/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import highlight1 from "assets/images/News/2025-03-23_Proefmaand/Foto.JPG"
import ImageOverlay from "components/ImageOverlay";

function Highlight() {
  return (
    <MKBox
      component="section"
      sx={{
        background: 'linear-gradient(to bottom,  #0092d2 0%,#0092d2 45%,#0c5ca3 100%)',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
      color="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <MKTypography
        variant="h1"
        color="white"
        sx={({ breakpoints, typography: { size } }) => ({
          [breakpoints.down("md")]: {
            fontSize: size["3xl"],
          },
        })}
      >
        Uitgelicht
      </MKTypography>
      <Container>
        <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
          <Grid item xs={12} lg={6}>
            <ImageOverlay image={highlight1} size="450em" alt="Examen Kandidaten" title="Foto tijdens de examens" />
          </Grid>
          <Grid item xs={12} lg={5}>
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Proefmaand Wado Karate
            </MKTypography>
            <p>
                De maand mei de kand voor iedereen om gratis mee te trainen bij ons met Wado Ryu Karate<br />
                Doe de allerbeste investering in jezelf.<br />
                Klik op het nieuwesbericht hieronder voor meer info. 
              </p>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Highlight;
