/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

import A2025_01_05_Oliebollenstage from "./activities/2025-01-05-Oliebollenstage"
import A2025_03_01_Daniel_Dubreuil from "./activities/2025-03-01-Daniel_Dubreuil";
import A2025_01_25_Examens from "./activities/2025-01-25-Examens";

function Activities() {
  return (
    <MKBox component="section" py={12}>
      <Container>
          <Grid container>
            <Grid item xs={12} md={8} sx={{ mb: 6 }}>
              <MKTypography
                  variant="h2"
                  color="Dark"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
              >
                  Meest Actuele activiteiten
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <A2025_03_01_Daniel_Dubreuil />
            <A2025_01_25_Examens />
            <A2025_01_05_Oliebollenstage />
          </Grid>
      </Container>
    </MKBox>
  );
}

export default Activities;
